<template>
  <div class="transaction-check-list-item" v-on:click="toggleCheck(transaction)">
    <div class="transaction-details" :class="{ unchecked: !transaction.isChecked }">
      <div class="date ri-font-14 margin-right-xs" v-if="showDate">{{ formatDate(transaction.transactionDate) }}</div>
      <div class="column-container">
        <div class="amount-container margin-bottom-xxs">
          <div class="ri-font-weight-bold ri-font-16">
            <span>{{ formatAmount(transaction.amount) }}</span>
            <span>₪</span>
          </div>
          <div class="split ri-font-12 margin-right-xxs"
               v-if="splitFromTransaction">מתוך  {{ getTransactionAmount(splitFromTransaction) }} ₪</div>
          <img class="margin-right-xs" v-if="transaction.isTemp" :src="require('@/assets/icons/temp_transaction_small.svg')"/>
        </div>
        <div class="ri-font-14 margin-bottom-xxs">
          {{ transaction.businessName }}
        </div>
        <div class="customer-comment-container ri-font-12" v-if="transaction.customerComment">
          <div class="customer-comment padding-xxs">{{ transaction.customerComment }}</div>
        </div>
        <div class="customer-comment-container ri-font-12" v-if="transactionPlan">
           <plan-ahead-transaction-label :key="transactionPlan"
                                        class="customer-comment padding-xxs"
                                        :plan-id="transactionPlan" />
        </div>
      </div>
    </div>
    <div class="checkbox">
      <div v-if="!transaction.isChecked" class="circle" :class="{ 'checked': transaction.isChecked }"/>
      <img v-else :src="require('@/assets/icons/icon-check-blue.svg')">
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils.vue';
import { mapGetters } from 'vuex';
import PlanAheadTransactionLabel
  from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanAheadTransactionLabel.vue';
import moneyUtils from '../utils/money';

export default {
  name: 'TransactionCheckListItem',
  mixins: [TransactionDisplayUtils],
  components: {
    PlanAheadTransactionLabel,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['transactionsInCurrentCashflow']),
    splitFromTransaction() {
      return this.transaction.splitFrom && _.find(this.transactionsInCurrentCashflow, { transactionId: this.transaction.splitFrom });
    },
    transactionPlan() {
      return this.transaction.plan;
    },
  },
  methods: {
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.transaction-check-list-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .transaction-details {
    display: flex;
    width: 100%;
    color: $riseup_black;
    margin: 15px 0;

    .date {
      flex: 1;
      margin-top: 2px;
    }

    .customer-comment-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .customer-comment {
      border-radius: 4px;
      display: inline-block;
      background-color: #D6D4D2;
    }

    .column-container {
      display: flex;
      flex-direction: column;
      flex: 3;

      .amount-container {
        display: flex;
        align-items: center;
      }
    }
  }

  .split {
    line-height: 18px;
    background-color: $riseup_gray_01;
    padding: 1px 5px;
    border-radius: 4px;
  }

  .checkbox {
    display: flex;
    align-items: center;

    .circle {
      display: inline-block;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $riseup_gray_2;
      background-color: $riseup_white;
    }
  }
}
</style>
