import { isNativeApp } from '@/utils/deviceUtils';

const MOBILE_MESSAGES_NAME = {
  PAGE_MOUNTED: 'pageMounted',
  LOGOUT: 'logout',
  SHARE_IMAGE: 'shareImage',
  SHARE_LINK: 'shareLink',
  DOWNLOAD_CSV: 'downloadCsv',
};

function dispatchMessageToMobile(name, detail) {
  if (isNativeApp()) {
    window.dispatchEvent(new CustomEvent(
      name,
      { detail },
    ));
  }
}

export default { dispatchMessageToMobile, MOBILE_MESSAGES_NAME };
