<template>
  <div class="update-creds-name-input" ref="credsNameInput">
    <loader v-if="loading" />
    <div v-else>
      <div class="name-description">איך לקרוא לחשבון?</div>
      <edit-text-input-field :field-type="'text'"
                             :field-value="name"
                             :error="errorMsg"
                             :update-input="updateName"
                             @blur="saveUpdatedCreds"/>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import EditTextInputField from '@/base-components/card-parts/EditTextInputField';
import _ from 'lodash';
import Loader from '@/base-components/Loader.vue';

export default {
  name: 'UpdateCredsNameInput',
  components: {
    Loader,
    EditTextInputField,
  },
  props: {
    source: Object,
    callback: Function,
  },
  data() {
    return {
      name: '',
      loading: false,
      errorMsg: '',
      hasMounted: false,
    };
  },
  created() {
    this.name = this.source.name;
  },
  computed: {
    ...mapState('credentials', ['credentialsList']),
    disabled() {
      return _.isEmpty(this.name) || this.name === this.source.name;
    },
  },
  methods: {
    ...mapActions('credentials', ['updateCredentialsName', 'setName']),
    ...mapActions('credentialsNew', ['fetchCredentialsSettings']),
    updateName(value) {
      this.name = value;
      this.errorMsg = '';
    },
    validateName() {
      if (this.name.length < 2) {
        this.errorMsg = 'השם צריך להיות באורך של לפחות 2 תווים';
        return;
      }
      const existingCreds = _.filter(this.credentialsList, {
        sourceName: this.source.sourceName,
        name: this.name,
      });
      if (existingCreds.length > 1) {
        this.errorMsg = 'כבר השתמשת בשם הזה';
      }
    },
    async saveUpdatedCreds() {
      this.loading = true;
      Segment.trackUserInteraction(
        'UpdateCreds_UpdateNameClicked',
        {
          sourceName: this.source.sourceName,
          oldName: this.source.name,
          newName: this.name,
        },
      );
      this.validateName();
      if (!this.errorMsg) {
        await this.setName({
          credentialsId: this.source.credentialsId,
          name: this.name,
        });
        const result = await this.updateCredentialsName({ source: this.source });
        if (result.success) {
          await this.fetchCredentialsSettings();
          this.callback();
        } else {
          this.errorMsg = 'שגיאה בעדכון השם';
        }
        this.loading = false;
      }
      this.loading = false;
    },
    handleClickOutside(event) {
      if (!this.hasMounted || _.includes(event.target.className, 'edit-icon')) {
        return;
      }
      const { credsNameInput } = this.$refs;
      if (credsNameInput && !credsNameInput.contains(event.target)) {
        this.saveUpdatedCreds();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside);
      this.hasMounted = true;
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
  .update-creds-name-input {
    .name-description {
      margin-bottom: 20px;
    }
  }
</style>
