<template>
  <div class="move-to-fixed-summary-popup">
    <popup :title="`ה${incomeOrExpense} תעבור`"
           :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:content>
        <change-category-path :old-category="originalCategory" :new-category="selectedCategory" />
        <transaction-details :transaction="transaction" />
        <div class="explanation" :class="selectedCategory.cashflowCategory">
          מעכשיו, המערכת תצפה ל{{ incomeOrExpense }} דומה {{sequenceMonthsCountMessage}}
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import MoveToFixedConfirmationPopup from './MoveToFixedConfirmationPopup.vue';
import TransactionDetails from './TransactionDetails.vue';
import ChangeCategoryPath from './ChangeCategoryPath.vue';
import Segment from '../../../../../../../../../../Segment';
import MoveToFixedBiMonthlyCheckPopupVue from './MoveToFixedBiMonthlyCheckPopup.vue';
import TransactionMovedToaster from '../../../../../toasters/TransactionMovedToaster';

export default {
  name: 'MoveToFixedSummaryPopup',
  components: {
    Popup: BaseUI.Popup,
    TransactionDetails,
    ChangeCategoryPath,
  },
  created() {
    Segment.trackUserInteraction('MoveToFixedSummaryPopup_Shown', {
      businessName: this.transaction.businessName,
      expenseCategory: this.transaction.expense,
      transactionId: this.transaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'categoryRuleType', 'monthsDiff']),
    ...mapGetters('editTransaction', ['selectedCategory', 'originalCategory', 'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense']),
    buttonOptions() {
      return [{
        title: 'אישור',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
    sequenceMonthsCountMessage() {
      if (this.monthsDiff === 2) {
        return 'פעם בחודשיים';
      }

      return 'גם בחודשים הבאים';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals']),
    ...mapActions('editCashflow', ['moveExpenseToFixed', 'moveIncomeToFixed']),
    back() {
      Segment.trackUserInteraction('MoveToFixedSummaryPopup_BackClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
        ExistingSequence: false,
      });
      const previousComponent = this.isIncomeCategory ? MoveToFixedConfirmationPopup : MoveToFixedBiMonthlyCheckPopupVue;
      this.$emit('close');
      this.openModal({
        component: previousComponent,
      });
    },
    async onSave() {
      Segment.trackUserInteraction('MoveToFixedSummaryPopup_ConfirmClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
        ExistingSequence: false,
      });
      if (this.isIncomeCategory) {
        await this.moveIncomeToFixed({
          transactionId: this.transaction.transactionId,
        });
      } else {
        await this.moveExpenseToFixed({
          transactionId: this.transaction.transactionId,
          monthsDiff: this.monthsDiff,
        });
      }
      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: this.transaction,
          targetCategory: this.selectedCategory,
        },
      });
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-to-fixed-summary-popup {

    .change-category-path {
      margin-bottom: 40px;
    }

    .transaction-details {
      margin-bottom: 24px;
    }

    .explanation {
      font-weight: bold;
      line-height: 16px;

      @include category-color;
    }
  }

</style>
