import { client, clientWithoutErrorHandling } from '../http';

async function getStory(storyName) {
  const response = await client.get(`/api/stories/${storyName}`);
  const { data } = response;
  return data;
}

async function getBites() {
  const response = await client.get('/api/member-bites');
  const { data } = response;
  return data;
}

async function getDailyRoutines() {
  const response = await client.get('/api/daily-routines');
  const { data } = response;
  return data;
}

function createBiteInput(input) {
  return client.post('/api/bite-input', input);
}

async function getSupermarketBenchmark() {
  const response = await client.get('/api/benchmark-supermarket');
  const supermarketBenchmark = response.data;
  return supermarketBenchmark;
}

async function getOrCreateAmbassadorLinkWithoutErrorHandling() {
  // We want to handle our errors in the ambassadors page, so we use client without error handling
  const response = await clientWithoutErrorHandling.post('/api/get-or-create-ambassador');
  return response.data.link;
}

async function updateId(idNumber) {
  const response = await client.put(
    '/api/ambassador/update-id',
    { idNumber },
  );
  return response.data;
}

function sendAnalyticsSignupByAmbassador(eventName, icrc) {
  return client.post(`api/no-auth/ambassador/analytics/${icrc}`, { eventName });
}

export default {
  getStory,
  getSupermarketBenchmark,
  getOrCreateAmbassadorLinkWithoutErrorHandling,
  updateId,
  sendAnalyticsSignupByAmbassador,
  getBites,
  createBiteInput,
  getDailyRoutines,
};
