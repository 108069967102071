<template>
  <div class="move-to-fixed-sequences-approval">
    <colored-popup :class="variant" :backAction="backAction">
      <template v-slot:top-content>
        <div class="approval-title">
          <span class="name">מעולה!</span>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="title">מעכשיו המערכת תצפה ל{{incomeOrExpense}} הקבועה הזו:</div>
        <div class="envelope-details">
            <span>{{predictedTransaction.businessName}}</span>
            <span>{{amount | shekel }}</span>
        </div>
        <div class="margin-top-xs">{{formatDate(predictedTransaction)}}, {{account}}</div>
        <div class="margin-top-xl margin-bottom-sm ri-body">
          כלומר, המערכת תצפה שגם בחודשים הבאים {{ incomeOrExpenseVerb }} {{incomeOrExpense}} בערך האותו סכום ובאותו בית עסק.</div>
        <div v-if="isPastEnvelope">
          <separator class="separator"/>
          <div>
            <img :src="require(`@/assets/icons/${exclamationMark}`)" class="icon">
            <span class=" ri-body">
            בחרת בהוצאה שהצפי שלה היה בחודש קודם, ולכן המערכת תזיז את העסקה הזו לחודש הקודם.
              </span>
          </div>

        </div>

        <riseup-button class="margin-top-ml"
                       @click="onSave"
                       title="אישור"/>
      </template>
    </colored-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import transactionUtils from '@/utils/transaction';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import _ from 'lodash';
import Segment from '@/Segment';

export default {
  name: 'MoveToFixedSequencesApproval',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    nextAction: {
      type: Function,
      required: true,
    },
    backAction: {
      type: Function,
      required: true,
    },
    selectedEnvelope: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedIndex: null,
  }),
  created() {
    Segment.trackUserInteraction('MoveToFixedSummaryPopup_Shown', {
      businessName: this.predictedTransaction.businessName,
      originalCategory: this.predictedTransaction.expense,
      originalPlacement: this.predictedTransaction.placement,
      transactionId: this.predictedTransaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'selectedCategoryName', 'originalCategoryName']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'incomeOrExpense']),
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
    incomeOrExpenseVerb() {
      return this.predictedTransaction.isIncome ? 'תכנס' : 'תצא';
    },
    variant() {
      return this.predictedTransaction.isIncome ? 'success' : 'fixed';
    },
    exclamationMark() {
      return this.predictedTransaction.isIncome ? 'exclamation-mark-green.svg' : 'exclamation-mark-pink.svg';
    },
    isPastEnvelope() {
      const envelopeMonth = _.split(this.selectedEnvelope.id, '#')[0];
      return this.predictedTransaction.transactionBudgetDate > envelopeMonth;
    },
    amount() {
      const amount = this.predictedTransaction.isIncome ? this.predictedTransaction.incomeAmount : this.predictedTransaction.billingAmount;
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    account() {
      return this.predictedTransaction.sourceType === 'checkingAccount'
        ? `חשבון ${this.predictedTransaction.accountNumberPiiValue}` : `כרטיס ${this.predictedTransaction.accountNumberPiiValue}`;
    },
  },
  methods: {
    formatDate(transaction) {
      return dateUtils.shortDateFormat(transaction.transactionDate);
    },
    onSave() {
      Segment.trackUserInteraction('MoveToFixedSummaryPopup_ConfirmClicked', {
        businessName: this.predictedTransaction.businessName,
        originalCategory: this.predictedTransaction.expense,
        originalPlacement: this.predictedTransaction.placement,
        transactionId: this.predictedTransaction.transactionId,
        ExistingSequence: true,
      });
      this.nextAction();
    },
    back() {
      Segment.trackUserInteraction('MoveToFixedSummaryPopup_BackClicked', {
        businessName: this.predictedTransaction.businessName,
        expenseCategory: this.predictedTransaction.expense,
        transactionId: this.predictedTransaction.transactionId,
        ExistingSequence: true,
      });
      this.backAction();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';
@import '@/scss/mixins';
.move-to-fixed-sequences-approval {
  text-align: right;

  .title {
    font-size: 22px;
    line-height: 32px;
    color: $riseup_black;
    margin-top: 15px;
    font-weight: bold;
  }
  .envelope-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $riseup_black;
    margin-top: 15px;
    font-weight: bold;
    }

  .approval-title {
    line-height: 11px;

    .name {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
    }
  }
  .separator {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .icon {
    height: 16px;
    width: 16px;
  }
}
</style>
