<template>
  <div class="update-creds-name-popup">
    <colored-line-popup :close-action="closePopup" :class="coloredLinePopupVariant">

      <template v-slot:top-content>
        <connected-credentials-name :source="source" :allow-edit="true" />
      </template>

      <template v-slot:bottom-content>
        <div class="account" v-for="(account, index) in orderedAccounts"
             :key="account.accountNumberPiiValue" :class="{ excluded: account.isExcluded }">
          <connected-account-details :index="index" :account="account" :type="source.type" />
          <edit-name :account="account"
                     :nickname="account.accountNickname"
                     :name-already-in-use-error="account.nameAlreadyInUseError"
                     :update-nickname="updateNickname"
                     :creds-type="source.type"
                     :raise-error="raiseError"
                     :reset-error="resetErrors" />
          <separator class="accounts-separator" v-if="orderedAccounts.length > 0 && index !== orderedAccounts.length - 1"/>
        </div>
        <div class="buttons-wrapper">
          <riseup-button :loading="loading" :size="'small'" :disabled="disabled" :title="'שמירה'" :action="save" :variant="'primary'"/>
        </div>
      </template>

    </colored-line-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import ConnectedCredentialsName from '@/base-components/credentials/credentials-list/ConnectedCredentialsName.vue';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ConnectedAccountDetails from '@/base-components/credentials/credentials-list/ConnectedAccountDetails.vue';
import EditName from './EditAccountNickname.vue';

export default {
  name: 'UpdateAccountNamePopup',
  components: {
    ConnectedAccountDetails,
    ColoredLinePopup: BaseUI.ColoredLinePopup,
    ConnectedCredentialsName,
    Separator: BaseUI.Separator,
    EditName,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    source: Object,
  },
  data() {
    return {
      loading: false,
      saveError: false,
      error: false,
      orderedAccounts: [],
    };
  },
  created() {
    this.orderedAccounts = _.chain(this.source.accounts)
      .cloneDeep()
      .sortBy(account => !!account.isExcluded)
      .map(account => {
        const currentNickname = this.accountListDict[account.accountNumberPiiId]?.accountNickname;
        return {
          ...account,
          accountNickname: currentNickname || account.accountNickname || '',
          nameAlreadyInUseError: false,
        };
      })
      .value();
  },
  computed: {
    ...mapGetters('credentials', ['accountListDict']),
    coloredLinePopupVariant() {
      return this.source.status === 'valid' ? 'success' : 'danger';
    },
    disabled() {
      return this.nameAlreadyInUseError || this.saveError || this.error;
    },
    nameAlreadyInUseError() {
      return _.some(this.orderedAccounts, 'nameAlreadyInUseError');
    },
  },
  methods: {
    ...mapActions('credentials', ['renameAccount']),
    ...mapActions('credentialsNew', ['fetchCredentialsSettings']),
    closePopup() {
      Segment.trackUserInteraction('UpdateAccount_CloseClicked', { sourceName: this.source.sourceName });
      this.$emit('close');
    },
    validateNames() {
      this.orderedAccounts = this.orderedAccounts.map(account => {
        const existingAccounts = _.find(this.orderedAccounts, a =>
          (a.accountNickname === account.accountNickname && a.accountNumberPiiId !== account.accountNumberPiiId));
        return {
          ...account,
          nameAlreadyInUseError: account.accountNickname !== '' && !!existingAccounts,
        };
      });
    },
    updateNickname(accountNumberPiiId, accountNickname) {
      const account = _.find(this.orderedAccounts, { accountNumberPiiId });
      account.accountNickname = accountNickname;
      this.validateNames(accountNickname);
    },
    async save() {
      this.loading = true;
      Segment.trackUserInteraction(
        'UpdateAccounts_SaveClicked',
        { sourceName: this.source.sourceName },
      );
      await Promise.all(this.orderedAccounts.map(async account => {
        await this.updateNames(account.accountNumberPiiId, account.accountNickname);
      }));
      this.closePopup();
      this.loading = false;
    },
    async updateNames(accountNumberPiiId, accountNickname) {
      const result = await this.renameAccount({ source: this.source, accountNumberPiiId, accountNickname });
      if (!result.success) {
        this.saveError = true;
      }
    },
    raiseError() {
      this.error = true;
    },
    resetErrors() {
      this.error = false;
      this.saveError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.update-creds-name-popup {
  text-align: right;

  .account {
    .separator {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .buttons-wrapper {
    margin-top: 35px;
    .riseup-button {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
</style>
