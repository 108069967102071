import _ from 'lodash';
import moment from 'moment';
import BillyApi from '@/api/BillyApi';

const state = {
  purchasedVouchersCountByVendorAndMonth: {},
};

const getters = {
  customerEverPurchasedVoucher: state => vendor =>
    _.keys(state.purchasedVouchersCountByVendorAndMonth[vendor] || {}).length > 0,
  currentMonthCountByVendor: state => vendor => {
    const currentMonth = moment().format('YYYY-MM');
    return (state.purchasedVouchersCountByVendorAndMonth[vendor] && state.purchasedVouchersCountByVendorAndMonth[vendor][currentMonth]) || 0;
  },
};

const actions = {
  async init({ commit, state }) {
    const purchasedVouchersCountByVendorAndMonth = await BillyApi.getVouchersStats();
    commit('setPurchasedVouchersCountByVendorAndMonth', purchasedVouchersCountByVendorAndMonth);
  },
};

const mutations = {
  setPurchasedVouchersCountByVendorAndMonth(state, purchasedVouchersCountByVendorAndMonth) {
    state.purchasedVouchersCountByVendorAndMonth = purchasedVouchersCountByVendorAndMonth;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
