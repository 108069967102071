<template>
  <div class="move-to-fixed-confirmation-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        <span class="selected-category" :class="selectedCategory.cashflowCategory">מה יקרה כשה{{ incomeOrExpense }} הזו תעבור לקבועות?</span>
      </template>
      <template v-slot:content>
        <div class="explanation">
          <div>המערכת תצפה שגם בחודשים הבאים {{ isIncomeCategory ? 'תכנס' : 'תצא' }} {{ incomeOrExpense }} דומה, כלומר, היא תקרה:</div>
          <div>
            בערך באותו זמן בחודש
            בערך באותו סכום
            ובאותו בית עסק</div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="delete-text" v-on:click="close">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import ChangeTransactionCategoryPopup from './ChangeTransactionCategoryPopup.vue';
import MoveToFixedSummaryPopup from './MoveToFixedSummaryPopup.vue';
import Segment from '../../../../../../../../../../Segment';
import MoveToFixedBiMonthlyCheckPopupVue from './MoveToFixedBiMonthlyCheckPopup.vue';

export default {
  name: 'MoveToFixedConfirmationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('MoveToFixedConfirmationPopup_Shown', {
      businessName: this.transaction.businessName,
      expenseCategory: this.transaction.expense,
      transactionId: this.transaction.transactionId,
    });
  },
  props: {
    // I add backAction prop Because we call this component from different places
    // and for some of them we need to use emit('close') and open new modal but
    // for other we need to use backAction for "Wizard" flow.
    // So I add this prop to make it more flexible. If we get the prop we use it otherwise we use emit('close')
    backAction: {
      type: Function,
      required: false,
    },
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapGetters('editTransaction', ['selectedCategory', 'ENRICHMENT_INPUT_TYPES', 'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense']),
    buttonOptions() {
      return [{
        title: 'הבנתי, זו הכוונה שלי',
        size: 'slim',
        action: () => this.onSave(),
        variant: 'primary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals', 'closeModal']),
    back() {
      Segment.trackUserInteraction('MoveToFixedConfirmationPopup_BackClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      if (this.backAction) {
        this.backAction();
        return;
      }
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    onSave() {
      Segment.trackUserInteraction('MoveToFixedConfirmationPopup_AnswerClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      const nextComponent = this.isIncomeCategory ? MoveToFixedSummaryPopup : MoveToFixedBiMonthlyCheckPopupVue;
      this.$emit('close');
      this.openModal({
        component: nextComponent,
        props: {},
      });
    },
    close() {
      Segment.trackUserInteraction('MoveToFixedConfirmationPopup_CloseClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-to-fixed-confirmation-popup {

    .explanation {
      line-height: 16px;
      white-space: pre-line;
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        color: $riseup_black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

</style>
