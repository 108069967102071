<template>
  <div class="move-to-fixed-sequences-list">
    <colored-popup :class="variant" :backAction="backAction">
      <template v-slot:top-content>
        <div class="transaction-title">
          <span class="name">איך הופיעה ה{{ incomeOrExpense }} הזו בחודשים הקודמים?</span>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div v-if="isRelevantTransaction" class="empty-list">
          לא נמצאו {{ incomeOrExpense }} שמתאימות לכאן
        </div>
        <div v-else>
          <envelopes-check-list :envelopes-list="relevantEnvelopes"  @select="onSelect"/>
          <riseup-button class="margin-top-ml"
                         @click="openApprovalPopup"
                         title="אישור"
                        :disabled="buttonDisabled"/>
        </div>
      </template>
    </colored-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import transactionUtils from '@/utils/transaction';
import cashflowHistory from '@/store/utilities/cashflow-history';
import _ from 'lodash';
import EnvelopesCheckList from '@/base-components/EnvelopesCheckList.vue';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import MoveToFixedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/MoveToFixedToaster.vue';
import MoveToFixedSequencesApproval from './MoveToFixedSequencesApproval.vue';

export default {
  name: 'MoveToFixedSequencesList',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    EnvelopesCheckList,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    backAction: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    selectedIndex: null,
  }),
  created() {
    Segment.trackUserInteraction('chooseEnvelopeToPutTransaction_Shown', {
      businessName: this.predictedTransaction.businessName,
      originalCategory: this.predictedTransaction.expense,
      originalPlacement: this.predictedTransaction.placement,
      transactionId: this.predictedTransaction.transactionId,
      envelopesCount: this.relevantEnvelopes.length,
    });
  },
  computed: {
    ...mapState('editTransaction', ['selectedCategoryName', 'originalCategoryName']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'incomeOrExpense']),
    ...mapState('cashflowView', ['allCashflows']),
    ...mapGetters('cashflowView', ['allSequences']),
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
    variant() {
      return this.predictedTransaction.isIncome ? 'success' : 'fixed';
    },

    relevantEnvelopes() {
      const { transactionBudgetDate } = this.predictedTransaction;
      const prevBudgetDate = cashflowHistory.getPrevBudgetDate(transactionBudgetDate);
      const twoMonthAgoBudgetDate = cashflowHistory.getPrevBudgetDate(prevBudgetDate);
      const currentAndPastMonthEnvelopes = _.chain(this.allCashflows)
        .filter(cashflow => [transactionBudgetDate, prevBudgetDate].includes(cashflow.budgetDate))
        .flatMap(cashflow => cashflow.envelopes)
        .filter(env => this.isRelevantEnvelope(env))
        .value();

      const twoMonthAgoEnvelopes = _.chain(this.allCashflows)
        .filter(cashflow => cashflow.budgetDate === twoMonthAgoBudgetDate)
        .flatMap(cashflow => cashflow.envelopes)
        .filter(env => this.isRelevantBiMonthlyEnvelope(env))
        .value();

      return [...currentAndPastMonthEnvelopes, ...twoMonthAgoEnvelopes];
    },
    buttonDisabled() {
      return this.selectedIndex === null;
    },
    isRelevantTransaction() {
      return this.relevantEnvelopes.length === 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals', 'closeModal']),
    ...mapActions('editCashflow', ['putTransactionToFixed']),
    isBiMonthlyEnvelope(envelope) {
      const transaction = envelope.details.sequence[0];
      if (!transaction) {
        return false;
      }
      if (transaction.monthsInterval === 2) {
        return true;
      }
      return false;
    },
    isRelevantEnvelope(envelope) {
      return (envelope.type === 'fixed'
        && envelope.actuals.length === 0
        && envelope.details.isIncome === this.predictedTransaction.isIncome
        && envelope.details.expense !== 'תשלומים'
        && envelope.details.expense !== 'RiseUp');
    },
    isRelevantBiMonthlyEnvelope(envelope) {
      return this.isRelevantEnvelope(envelope) && this.isBiMonthlyEnvelope(envelope);
    },
    onSelect(index) {
      this.selectedIndex = index;
    },
    openApprovalPopup() {
      Segment.trackUserInteraction('chooseEnvelopeToPutTransaction_ConfirmClicked', {
        businessName: this.predictedTransaction.businessName,
        originalCategory: this.predictedTransaction.expense,
        originalPlacement: this.predictedTransaction.placement,
        transactionId: this.predictedTransaction.transactionId,
        envelopeId: this.relevantEnvelopes[this.selectedIndex].id,
        envelopeBusinessName: this.relevantEnvelopes[this.selectedIndex].details.businessName,
        envelopeExpanse: this.relevantEnvelopes[this.selectedIndex].details.expense,
      });
      this.openModal({
        component: MoveToFixedSequencesApproval,
        props: {
          predictedTransaction: this.predictedTransaction,
          backAction: this.closeModal,
          nextAction: this.PutTransactionToSequence,
          selectedEnvelope: this.relevantEnvelopes[this.selectedIndex],
        },
      });
    },
    async PutTransactionToSequence() {
      const sequence = this.allSequences[this.relevantEnvelopes[this.selectedIndex].details.sequenceId];
      const lastTransactions = _.chain(sequence).sortBy(x => x.transactionDate)
        .takeRight(3)
        .map(t => t.transactionId)
        .value();
      Segment.trackUserInteraction('MoveToFixedSequenceSaveClicked', {
        envelopeId: this.relevantEnvelopes[this.selectedIndex].id,
        cashflowMonth: this.presentedBudgetDate,
        transactionId: this.predictedTransaction.transactionId,
        sequenceId: this.relevantEnvelopes[this.selectedIndex].details.sequenceId,
        lastTransactions,
      });
      await this.putTransactionToFixed({
        envelopeId: this.relevantEnvelopes[this.selectedIndex].id,
        transactionId: this.predictedTransaction.transactionId,
        sequenceId: this.relevantEnvelopes[this.selectedIndex].details.sequenceId,
        lastTransactions,
      });
      EventBus.$emit('open-toaster', {
        component: MoveToFixedToaster,
        props: {
          isIncome: this.predictedTransaction.isIncome,
          isFixed: this.predictedTransaction.placement === 'fixed',
        },
      });
      this.closeAllModals();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';
@import '@/scss/mixins';
.move-to-fixed-sequences-list {
  text-align: right;

  .separator {
    @include responsive-padding;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: $riseup_gray_2;

    .line {
      height: 1px;
      width: 100%;
      background-color: $riseup_gray_1;
    }

    .or {
      font-family: SimplerPro;
      color: $riseup_black;
      margin: 0 15px;
      font-size: small;
    }
  }
  .transaction-title {
    line-height: 11px;

    .name {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
    }
  }
  .empty-list {
    font-size: 18px;
    font-family: SimplerPro;
    font-weight: 400;
    color: $riseup_gray_2;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 24px;
  }
}
</style>
