<template>
  <div class="transaction-details ri-body">
    <div class="amount">
      <span>{{ getTransactionAmount(transaction) }}</span>
      <span class="nis-currency">₪</span>
    </div>
    <div class="business-name">{{ transaction.businessName }}</div>
    <div v-if="useContentDetails" class="ri-bold-body" >
      <slot name="transaction-details-content"></slot>
    </div>
    <div v-else>
      <div class="account">{{ getTransactionAccountHeader(transaction) }}</div>
      <div class="date">{{ formatDate(transaction.transactionDate) }}</div>
    </div>
  </div>
</template>

<script>
import TransactionDisplayUtils from '../../../../generic-components/TransactionDisplayUtils.vue';

export default {
  name: 'TransactionDetails',
  mixins: [TransactionDisplayUtils],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    useContentDetails: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .transaction-details {
    .amount {
      font-size: 50px;
      line-height: 50px;
      font-weight: bold;
      margin-bottom: 10px;

      .nis-currency {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }

</style>
