<script>

import { getBanks } from '@/constants/sourcesConfiguration';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CustomerBenefits',
  computed: {
    ...mapGetters('featureFlags', ['openOneZero']),
    sortedCombinedBankSources() {
      const bankConfigurations = getBanks();
      return _.chain(bankConfigurations)
        .sortBy(b => b.sortPriority)
        .reject(bank => !this.openOneZero && bank.id === 'onezero')
        .value();
    },
  },
};

</script>
