<template>
  <div class="move-to-fixed-flow">
    <move-to-fixed-details-popup v-if="componentIndex === 0"
                                :predicted-transaction="transaction"
                                 :move-to-add-to-existing-sequence="moveToAddToExistingSequence"
                                 :move-to-create-new-sequence="moveToCreateNewSequence"/>

    <move-to-fixed-confirmation-popup v-else-if="componentIndex === 1" :back-action="back"/>

    <move-to-fixed-sequences-list v-else-if="componentIndex === 3"
                                      :close-action="closeAction"
                                      :predicted-transaction="transaction"
                                      :back-action="back"/>
    <edit-predicted-transaction-summary v-else
                                      :predicted-transaction="transaction"
                                      :back-action="back"
                                      :close-action="closeAction"
                                      :old-expense-category="oldExpenseCategory"
                                      :new-expense-category="newExpenseCategory" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import Segment from '@/Segment';
import consts from '@/constants/cashflow-view';
import MoveToFixedConfirmationPopup from '../../MoveToFixedConfirmationPopup.vue';
import EditPredictedTransactionSummary from '../EditPredictedTransactionSummary.vue';
import MoveToFixedDetailsPopup from '../MoveToFixedDetailsPopup.vue';
import MoveToFixedSequencesList from '../MoveToFixedSequencesList.vue';

export default {
  name: 'MoveToFixedFlow',
  components: {
    EditPredictedTransactionSummary,
    MoveToFixedDetailsPopup,
    MoveToFixedConfirmationPopup,
    MoveToFixedSequencesList,
  },
  created() {
    this.componentIndex = 0;
    this.oldExpenseCategory = this.transaction.expense;
  },
  data() {
    return {
      componentIndex: 0,
      oldExpenseCategory: null,
      newExpenseCategory: null,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['fixedExpenseCategories', 'fixedIncomeCategories']),
    ...mapState('editTransaction', ['transaction', 'selectedCategoryName', 'originalCategoryName']),
    fixedCategories() {
      const categories = this.transaction.isIncome
        ? [...this.fixedIncomeCategories, ...consts.TRANSFERABLE_FIXED_INCOME_CATEGORIES]
        : [...this.fixedExpenseCategories, ...consts.TRANSFERABLE_FIXED_EXPENSE_CATEGORIES];
      return _.chain(categories)
        .uniq()
        .sortBy()
        .reject(category => category === consts.OTHER_CATEGORY)
        .reject(category => consts.FIXED_SAVING_CATEGORY_NAMES.includes(category))
        .concat(consts.OTHER_CATEGORY)
        .value();
    },
  },
  methods: {
    setNewExpenseCategory(newExpenseCategory) {
      this.newExpenseCategory = newExpenseCategory.trim();
    },
    closeAction() {
      Segment.trackUserInteraction('EMoveToFixedFlow_Closed', {
        transaction: this.transaction,
      });
      this.$emit('close');
    },
    back() {
      this.componentIndex = 0;
    },
    next() {
      this.componentIndex = 2;
    },
    moveToAddToExistingSequence() {
      this.componentIndex = 3;
    },
    moveToCreateNewSequence() {
      this.componentIndex = 1;
    },
  },
};
</script>
