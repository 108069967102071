<template>
  <div class="envelope-check-list-item" v-on:click="onClick">
    <div class="envelope-details" :class="{ unchecked: !isSelected }">
      <div class="business-name"> {{envelope.details.businessName}}</div>
      <div class="details">
        <div class="amount">
          <span>סכום צפוי:</span>
          <span>{{formatAmount(envelope.balancedAmount) | shekel }}</span>
        </div>
        <div class="date">
          <span>צפי:</span>
          <span>{{formatDate(envelope.details.transactionDate)}}</span>
        </div>
      </div>
      <div v-if="envelope.sequenceCustomerComment" class="customer-comment-container">
        <div class="customer-comment">{{envelope.sequenceCustomerComment}}</div>
      </div>
    </div>
    <div class="checkbox">
      <div v-if="!isSelected" class="circle" :class="{ 'checked': envelope.isChecked }"/>
      <img v-else :src="require('@/assets/icons/icon-check-blue.svg')">
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils.vue';
import { mapGetters } from 'vuex';
import moneyUtils from '../utils/money';

export default {
  name: 'EnvelopeCheckListItem',
  mixins: [TransactionDisplayUtils],
  props: {
    envelope: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['transactionsInCurrentCashflow']),
  },
  methods: {
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
    onClick(event) {
      this.$emit('select', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.envelope-check-list-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

    .envelope-details {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $riseup_black;
      margin: 15px 0;

        .business-name {
          font-size: 18px;
          font-weight: bold;
          line-height: 20px;
        }
        .details {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 8px;

          .amount {
            font-size: 16px;
            weight: 400;
          }
          .date {
            font-size: 14px;
            weight: 400;
            color: $riseup_gray_2;
            margin-right: 10px;
          }
        }
        .date {
          flex: 1;
          margin-top: 2px;
        }
      .customer-comment-container {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        line-height: 12px;

        .customer-comment {
          font-size: 12px;
          border-radius: 4px;
          margin-left: 8px;
          margin-top: 4px;
          margin-bottom: 4px;
          display: inline-block;
          padding: 5px;
          background-color: $riseup_gray_5;
        }
      }
      }
  .split {
    line-height: 18px;
    background-color: $riseup_gray_01;
    padding: 1px 5px;
    border-radius: 4px;
  }

  .checkbox {
    display: flex;
    align-items: center;

    .circle {
      display: inline-block;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $riseup_gray_2;
      background-color: $riseup_white;
    }
  }
}
</style>
