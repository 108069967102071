<template>
  <div class="daily-routine-viewer">
    <div class="content">
        <div class="page-content" ref="pageContent">
          <div class="close-action" v-on:click="close">
            <img :src="require(`@/assets/icons/close-white.svg`)"/>
          </div>
          <page-viewer class="viewer" content-type="daily-routine" :ratio="selectedDailyRoutine.page.ratio"
                       :pageId="0" :page-content="selectedDailyRoutine.page.content" />
          <div class="buttons flex-row">
            <riseup-button :title="firstAction.text" variant="secondary" :action="() => handleAction(firstAction.action)" class="action-button"/>
            <div class="remaining">נשארו עוד {{dailyRoutines.length}}</div>
            <riseup-button v-if="secondAction" :title="secondAction.text" variant="secondary"
                           :action="() => handleAction(secondAction.action)" class="action-button"/>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import storyLib from '@riseupil/story-lib';
import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'DailyRoutineViewer',
  components: {
    PageViewer: storyLib.PageViewer,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    this.initDailyRoutine();
  },
  data() {
    return {
      currentPageIndex: 0,
    };
  },
  computed: {
    ...mapState('dailyRoutines', ['selectedDailyRoutine', 'dailyRoutines']),
    firstAction() {
      return this.selectedDailyRoutine.actions[0];
    },
    secondAction() {
      return this.selectedDailyRoutine.actions.length > 1 ? this.selectedDailyRoutine.actions[1] : null;
    },
  },
  methods: {
    ...mapActions('dailyRoutines', ['initDailyRoutine', 'dismissDailyRoutine']),
    close() {
      this.$emit('close');
    },
    next() {
      this.dismissDailyRoutine();
    },
    handleAction(type) {
      if (type === 'dismiss') {
        this.next();
      } else {
        this.next();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .daily-routine-viewer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    user-select: none;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      background: #00000066;

      .close-action {
        height: 60px;
        margin: 10px;
      }

      .buttons {
        height: 100px;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        margin: 0 10px;

        .action-button {
          width: 80px;
        }
      }

      .page-content {
        flex-grow: 1;
        position: relative;

        .viewer {
          border-radius: 15px;
          margin: 0 10px;
        }
      }
    }
}
</style>
