<template>
  <button v-if="subscriptionStateText" class="trial-state-snackbar ri-label" :class="subscriptionStateVariant" @click="subscriptionStateClicked">
    <span v-if="subscriptionStateText === 'trialText'"><span class="ri-bold-label">{{ daysLeftText }}</span> {{ trialCounterText }}</span>
    <span v-else class="ri-bold-label">{{ subscriptionStateText }}</span>
    <icon v-if="!hideArrow" icon-name="arrow_back_ios_new" size="14px" weight="bold"/>
  </button>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { SUBSCRIPTION_STATES, TRIAL_PERIODS } from '@/constants/subscription-state';
import trialUtils from '@/pages/responsive-pages/authenticated/trial/trial-utils';
import { mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'TrialStateSnackbar',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    hideArrow: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState('subscriptionState', ['subscriptionState']),
    ...mapGetters('subscriptionState', ['daysLeft', 'trialPeriod']),
    subscriptionStateVariant() {
      switch (this.subscriptionState) {
      case SUBSCRIPTION_STATES.PRE_TRIAL:
        return 'warning';
      case SUBSCRIPTION_STATES.TRIAL:
        return this.periodVariant;
      case SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED:
        return 'danger';
      case SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL:
        return 'red';
      case SUBSCRIPTION_STATES.FREE:
        return 'warning';
      case SUBSCRIPTION_STATES.SUBSCRIBED:
        return 'warning';
      case SUBSCRIPTION_STATES.CANCELED:
        return 'danger';
      default:
        return 'warning';
      }
    },
    subscriptionStateText() {
      switch (this.subscriptionState) {
      case SUBSCRIPTION_STATES.PRE_TRIAL:
        return '';
      case SUBSCRIPTION_STATES.TRIAL:
        return 'trialText';
      case SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED:
        return 'המנוי לא פעיל';
      case SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL:
        return 'המנוי לא פעיל';
      case SUBSCRIPTION_STATES.FREE:
        return '';
      case SUBSCRIPTION_STATES.SUBSCRIBED:
        return '';
      case SUBSCRIPTION_STATES.CANCELED:
        return '';
      default:
        return '';
      }
    },
    daysLeftText() {
      return trialUtils.humanizeDays(this.daysLeft);
    },
    trialCounterText() {
      if (this.daysLeft === 1) {
        return 'נותר לתקופת הניסיון';
      }
      return this.daysLeftText ? 'נותרו לתקופת הניסיון' : 'תקופת הניסיון הסתיימה';
    },
    periodVariant() {
      switch (this.trialPeriod) {
      case TRIAL_PERIODS.BEGINNING: return 'default';
      case TRIAL_PERIODS.MIDDLE: return 'warning';
      case TRIAL_PERIODS.FINAL: return 'danger';
      default: return 'warning';
      }
    },
  },
  methods: {
    subscriptionStateClicked() {
      Segment.trackUserInteraction('NavigationMenu_SubscriptionStatusClicked', { trialPeriod: this.trialPeriod });
      this.$router.push({
        name: 'AccountSettings',
        query: this.$route.query,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.trial-state-snackbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: $ri-border-radius-medium;
  cursor: pointer;
  width: 100%;
  color: $riseup_beige;
  padding: $ri-spacing-xs $ri-spacing-sm $ri-spacing-xs $ri-spacing-xs;

  &.default{
    background: $riseup_green;
  }

  &.warning {
    background: $riseup_dark_yellow;
  }

  &.danger {
    background: $riseup_danger_red;
  }

  &.red {
    background: $riseup_danger_red;
  }
}

</style>
