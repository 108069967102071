<template>
  <div class="envelops-check-list">
    <div v-for="(envelope, index) in envelopesList" :key="index" class="envelope-row">
      <envelope-check-list-item :envelope="envelope" :is-selected="index === selectedIndex"
                                @select="onSelect(index)"/>
      <separator class="separator"  v-if="index < envelopesList.length - 1"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import EnvelopeCheckListItem from '@/base-components/EnvelopeCheckListItem.vue';

export default {
  name: 'EnvelopesCheckList',
  components: {
    Separator: BaseUI.Separator,
    EnvelopeCheckListItem,
  },
  data: () => ({
    selectedIndex: null,
  }),
  props: {
    envelopesList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onSelect(index) {
      this.selectedIndex = index;
      this.$emit('select', index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.envelopes-check-list {
  .separator {
    margin-bottom: 18px;
    margin-top: 18px;
  }
}

</style>
