<template>
  <div class="change-transaction-category-popup">
    <colored-popup :class="variant" :back-action="backAction" :close-action="closeAction">
      <template v-slot:top-content>
        <div class="transaction-title">
          <span class="name">{{ incomeOrExpense }} קבועה</span>
        </div>
        <transaction-details :transaction="predictedTransaction" />
      </template>

      <template v-slot:bottom-content>
        <div class="question">איזו {{ incomeOrExpense }} קבועה זאת?</div>
          <riseup-button class="margin-top-ml"
                    @click="MoveToNewSequence"
                    :title="newButtonTitle"/>

        <div class="separator">
          <div class="line"></div>
          <div class="or">או</div>
          <div class="line"></div>
        </div>

        <riseup-button class="margin-top-ml"
                       @click="MoveToExistingSequence"
                       :title="alreadyExistButtonTitle"/>
      </template>
    </colored-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
// eslint-disable-next-line max-len
import TransactionDetails from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/TransactionDetails.vue';
import transactionUtils from '@/utils/transaction';
import Segment from '@/Segment';
import ChangeTransactionCategoryPopup from '../ChangeTransactionCategoryPopup.vue';

export default {
  name: 'MoveToFixedDetailsPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    MoveToCreateNewSequence: {
      type: Function,
      required: true,
    },
    MoveToAddToExistingSequence: {
      type: Function,
      required: true,
    },
    useCloseAction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserInteraction('WhichFixedExpanseIsItPopup_Shown', {
      businessName: this.predictedTransaction.businessName,
      originalCategory: this.predictedTransaction.expense,
      originalPlacement: this.predictedTransaction.placement,
      transactionId: this.predictedTransaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'selectedCategoryName', 'originalCategoryName']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'incomeOrExpense']),
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
    variant() {
      return this.predictedTransaction.isIncome ? 'success' : 'fixed';
    },
    alreadyExistButtonTitle() {
      return `${this.incomeOrExpense} קבועה שכבר קרתה בעבר`;
    },
    newButtonTitle() {
      if (this.predictedTransaction.placement === 'fixed' && !this.predictedTransaction.isIncome) {
        return 'הוצאה קבועה חדשה מסוג אחר';
      }
      return `${this.incomeOrExpense} קבועה חדשה`;
    },
    backAction() {
      return this.useCloseAction ? null : this.back;
    },
    closeAction() {
      return this.useCloseAction ? this.closeModal : null;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    back() {
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    MoveToNewSequence() {
      Segment.trackUserInteraction('WhichFixedExpanseIsItPopup_NewFixedClicked', {
        businessName: this.predictedTransaction.businessName,
        originalCategory: this.predictedTransaction.expense,
        originalPlacement: this.predictedTransaction.placement,
        transactionId: this.predictedTransaction.transactionId,
      });
      this.MoveToCreateNewSequence();
    },
    MoveToExistingSequence() {
      Segment.trackUserInteraction('WhichFixedExpanseIsItPopup_ExistingFixedClicked', {
        businessName: this.predictedTransaction.businessName,
        originalCategory: this.predictedTransaction.expense,
        originalPlacement: this.predictedTransaction.placement,
        transactionId: this.predictedTransaction.transactionId,
      });
      this.MoveToAddToExistingSequence();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';
@import '@/scss/mixins';

.separator {
  @include responsive-padding;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: $riseup_gray_2;

  .line {
    height: 1px;
    width: 100%;
    background-color: $riseup_gray_1;
  }

  .or {
    font-family: SimplerPro;
    color: $riseup_black;
    margin: 0 15px;
    font-size: small;
  }
}
</style>
