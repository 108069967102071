<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import moneyUtils from '../../../../../../../utils/money';

function getTransactionFrom(transaction) {
  return transaction.sourceType === 'creditCard' ? 'כרטיס' : 'חשבון';
}

function getAccountNickname(accountListDict, transaction) {
  const account = accountListDict[transaction && transaction.accountNumberPiiId];
  return account && account.accountNickname;
}

function _formatBudgetzAccountNumber(transaction) {
  const onlyAccountNumberNoBranch = transaction.accountNumberPiiValue.split('-')[1];
  return onlyAccountNumberNoBranch.substring(3);
}

export default {
  name: 'TransactionDisplayUtils',
  computed: {
    ...mapGetters('credentials', ['accountListDict']),
  },
  methods: {
    getTransactionAmount(transaction) {
      const amount = transaction.incomeAmount > 0 ? transaction.incomeAmount : transaction.billingAmount;
      if (amount >= 1000) {
        return moneyUtils.formatAmountWithFraction(amount, 0);
      }
      return moneyUtils.formatAmountWithFraction(amount);
    },
    getTransactionAmountWithoutFraction(transaction) {
      return Number(transaction.incomeAmount > 0 ? transaction.incomeAmount : transaction.billingAmount);
    },
    formatAmount(amount) {
      return !_.isNil(amount) ? `${amount} ₪` : amount;
    },
    formatAmountWithFractionAndCurrency(number, fractionDigits = 0) {
      return this.formatAmount(moneyUtils.formatAmountWithFraction(number, fractionDigits));
    },
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
    getTransactionAccountHeader(transaction) {
      const transactionFrom = getTransactionFrom(transaction);
      const accountNickname = getAccountNickname(this.accountListDict, transaction);
      const accountNumber = transaction.source === 'budgetz' ? _formatBudgetzAccountNumber(transaction) : transaction.accountNumberPiiValue;
      if (accountNickname) {
        return `${transactionFrom} ${accountNickname} | ${accountNumber}`;
      }
      if (transaction.source === 'budgetz') {
        return `${transactionFrom} Budgetz | ${accountNumber}`;
      }
      return `${transactionFrom} ${accountNumber}`;
    },
    getTransactionPayment(transaction) {
      return transaction.paymentNumber > 0 ? `- תשלום ${transaction.paymentNumber}/${transaction.totalNumberOfPayments}` : '';
    },
    getPaymentAppDetails(transaction) {
      const transferFromOrToText = transaction.isIncome ? 'העברה מ' : 'העברה ל';
      const paymentAppComment = transaction.paymentAppComment || '';
      const addresseeText = transaction.paymentAppAddressee && `${transferFromOrToText}${transaction.paymentAppAddressee}`;
      const commentText = paymentAppComment
        && (addresseeText ? ` '${paymentAppComment}'` : paymentAppComment);
      return (addresseeText || commentText) && `${addresseeText || ''}${commentText || ''}`.trim();
    },
  },
};
</script>
