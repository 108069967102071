import { render, staticRenderFns } from "./CredentialsMenu.vue?vue&type=template&id=6b70df07&scoped=true"
import script from "./CredentialsMenu.vue?vue&type=script&lang=js"
export * from "./CredentialsMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b70df07",
  null
  
)

export default component.exports