<template>
  <div class="nav-bar" role="menu" tabindex="0" ref="menu">
    <navigation-menu-header :class="promotion ? 'margin-bottom-ml' : 'margin-bottom-l'" :close-modal="closeModal" />
    <img v-if="promotion" class="promotion-banner" :src="`/images/promotions/${promotion.imgName}`"
         @click="openPromotion" @keyup.enter="openPromotion" tabindex="0" :alt="promotion.description"/>
    <navigation-menu-item class="margin-top" title="תזרים חודשי" v-on:navigate="closeModal" target="sr" icon-name="database"/>
    <balance-page-menu-item v-on:navigate="closeModal"/>
    <navigation-menu-item icon-name="chart_data" title="ההתקדמות שלי"
                          v-on:navigate="closeModal" target="my-progress" :disabled="!customerStateIsActive"
                          segmentEventSuffix="MyProgress"/>
    <navigation-menu-item  v-if="!preventPayments" icon-name="local_grocery_store" title="תווי קניה לסופר"
                          v-on:navigate="closeModal" :target="vouchersTarget"
                          :disabled="!customerStateIsActive"
                          segmentEventSuffix="SuperDiscount" show-new-badge/>
    <plan-ahead-menu-item v-on:navigate="goToPlanAheadPage"/>
    <saving-zone-menu-item v-on:navigate="closeModal"/>
    <balance-money-menu-item v-on:navigate="closeModal" target-path="balance-money"/>
    <navigation-menu-item title="כמה עלה לי?" v-on:navigate="closeModal" target="stories" icon-name="calculate"/>
    <navigation-menu-item v-if="showExperimentalPages" title="רעיונות בפיתוח" v-on:navigate="closeModal"
                          target="experimental-pages" icon-name="lightbulb"/>
    <div class="tiles-grid">
      <menu-tile v-for="tile in menuTiles" v-bind="tile" :key="tile.text" @click="tile.onClick"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapActions, mapState } from 'vuex';
import PlanAheadIntroductionPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanAheadIntroductionPopup';
import AddPlanFlow from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-flow/AddPlanFlow';
import MenuTile from '@/base-components/app-header/MenuTile';
import { SUBSCRIPTION_STATES } from '@/constants/subscription-state';
import BalanceMoneyMenuItem from './navigation-menu-items/BalanceMoneyMenuItem';
import BalancePageMenuItem from './navigation-menu-items/BalancePageMenuItem.vue';
import SavingZoneMenuItem from './navigation-menu-items/SavingZoneMenuItem';
import PlanAheadMenuItem from './navigation-menu-items/PlanAheadMenuItem';
import router from '../../router';
import Intercom from '../../Intercom';
import Segment from '../../Segment';
import NavigationMenuItem from './NavigationMenuItem.vue';
import NavigationMenuHeader from './NavigationMenuHeader';

export default {
  name: 'NavigationMenu',
  components: {
    MenuTile,
    SavingZoneMenuItem,
    BalanceMoneyMenuItem,
    BalancePageMenuItem,
    NavigationMenuItem,
    PlanAheadMenuItem,
    NavigationMenuHeader,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters(
      'featureFlags',
      ['keepAmbassadorsInApp', 'showExperimentalPages', 'showPlanAheadIntroPopup',
        'showFinancialWarSection', 'showAmbassadorsMenuBannerSection',
        'showAdvisorySingleMenuBanner', 'showMyProgressMenuBanner', 'showNewVouchersFlow', 'eoyMenuBannerSection', 'gzerotMenuBannerSection',
        'preventPayments'],
    ),
    ...mapGetters('planAhead', ['hasPlans']),
    ...mapGetters('advisory', ['advisoryFlowSharedUTMParams']),
    ...mapGetters('referrals', ['isDuringCompetition']),
    ...mapState('subscriptionState', ['subscriptionState']),
    ...mapState('applicationState', ['cashflowState']),
    ...mapGetters('applicationState', ['cashflowTypes']),
    menuTiles() {
      if (this.preventPayments) {
        return [{
          text: 'הקהילה שלנו בפייסבוק',
          iconProps: {
            iconName: 'diversity_4',
            fill: true,
            color: BaseUI.Colors.riseupBlue,
          },
          onClick: this.goToFacebook,
        },
        {
          text: 'מרכז המידע',
          iconProps: {
            iconName: 'book_2',
            fill: true,
            color: BaseUI.Colors.riseupDangerRed,
          },
          onClick: this.openFaq,
        }];
      }

      return [
        {
          text: 'להמליץ ולהרוויח',
          // eslint-disable-next-line global-require
          imgSrc: require('@/assets/icons/gift.svg'),
          onClick: this.goToAmbassadorsFromNavigationMenu,
        },
        {
          text: 'הקהילה שלנו בפייסבוק',
          iconProps: {
            iconName: 'diversity_4',
            fill: true,
            color: BaseUI.Colors.riseupBlue,
          },
          onClick: this.goToFacebook,
        },
        {
          text: 'מרכז המידע',
          iconProps: {
            iconName: 'book_2',
            fill: true,
            color: BaseUI.Colors.riseupDangerRed,
          },
          onClick: this.openFaq,
        },
        {
          text: 'צ׳אט עם הצוות',
          iconProps: {
            iconName: 'forum',
            fill: true,
            color: BaseUI.Colors.riseupYellow,
          },
          onClick: this.openChat,
        },
      ];
    },
    promotion() {
      if (this.isDuringCompetition) {
        return {
          key: 'referrals-competition',
          description: 'תחרות ההמלצות של רייזאפ',
          imgName: 'ambassadors-contest.png',
          onClick: () => this.goToAmbassadors(),
        };
      }
      if (this.showFinancialWarSection) {
        return {
          key: 'financial-war',
          description: 'על זה, עכשיו. ריכזנו את כל הפעולות שכדאי לעשות עכשיו עם הכסף שלך',
          imgName: 'financial-war-banner.png',
          onClick: () => window.open('https://www.riseup.co.il/whattodonow', '_blank'),
        };
      }
      if (this.showMyProgressMenuBanner) {
        return {
          key: 'my-progress-menu-banner',
          description: 'לראות את ההתקדמות שלי',
          imgName: 'my_progress_banner.png',
          onClick: () => this.openMyProgress(),
        };
      }
      if (!this.preventPayments && this.showAmbassadorsMenuBannerSection) {
        return {
          key: 'ambassadors-menu-banner',
          description: 'לפרגן במינוי בהנחה ולהרוויח על הדרך',
          imgName: 'ambassador_banner_new.svg',
          onClick: () => this.goToAmbassadors(),
        };
      }
      if (this.eoyMenuBannerSection) {
        return {
          key: 'eoy-menu-banner',
          description: 'סיכום השנה שלך מוכן!',
          imgName: 'eoy_banner.svg',
          onClick: () => this.goToEoy(),
        };
      }
      if (this.gzerotMenuBannerSection) {
        return {
          key: 'gzerot-menu-banner',
          description: 'גזירות 2025',
          imgName: 'gzerot_fb_banner.svg',
          onClick: () => this.goToGzerotStory(),
        };
      }

      return undefined;
    },
    customerStateIsActive() {
      return !this.isDormant
          && !this.isPreTrial
          && !this.cashflowOutOfDate
          && !this.cashflowBlocked;
    },
    isDormant() {
      return [SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED, SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL].includes(this.subscriptionState);
    },
    isPreTrial() {
      return SUBSCRIPTION_STATES.PRE_TRIAL === this.subscriptionState;
    },
    cashflowOutOfDate() {
      return this.cashflowState === this.cashflowTypes.OUT_OF_DATE;
    },
    cashflowBlocked() {
      return this.cashflowState === this.cashflowTypes.BLOCKED;
    },
    advisoryRouteTarget() {
      return {
        path: '/advisory-flow-external',
        query: {
          ...this.advisoryFlowSharedUTMParams,
          utm_content: 'menu',
          utm_campaign: 'menu-item',
        },
      };
    },
    vouchersTarget() {
      if (this.showNewVouchersFlow) {
        return 'super-discount-v2/all';
      }
      return 'super-discount';
    },
  },
  created() {
    if (this.promotion) {
      Segment.trackUserInteraction(`NavigationMenu_${this.promotion.key}BannerShown`);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.menu.focus();
    });
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('planAhead', ['addPlan']),
    openFaq() {
      Segment.trackUserInteraction('NavigationMenu_HelpCenterClicked');
      window.open('https://intercom.riseup.co.il/he?utm_source=help-center&utm_medium=riseup-menue', '_blank');
      this.closeModal();
    },
    async redirectToLogin() {
      await this.logout();
      this.closeModal();
    },
    goToFacebook() {
      Segment.trackUserInteraction('NavigationMenu_FacebookGroup');
      window.open('https://www.facebook.com/groups/riseuptogethergroup', '_blank');
      this.closeModal();
    },
    goToAmbassadors() {
      if (this.keepAmbassadorsInApp) {
        router.push({ path: '/ambassadors' });
      } else {
        window.open('https://www.riseup.co.il/ambassadors', '_blank');
      }
      this.closeModal();
    },
    goToEoy() {
      router.push({ path: '/st/eoy2024' });
      this.closeModal();
    },
    goToGzerotStory() {
      router.push({ path: '/st/gzerot-Feb' });
      this.closeModal();
    },
    goToAmbassadorsFromNavigationMenu() {
      Segment.trackUserInteraction('NavigationMenu_AmbassadorsClicked');
      this.goToAmbassadors();
    },
    openAdvisorySingleFlow() {
      Segment.trackUserInteraction('NavigationMenu_AdvisorySingleClicked');
      router.push({
        path: '/advisory-flow-external',
        query: {
          ...this.advisoryFlowSharedUTMParams,
          utm_content: 'menu',
          utm_campaign: 'menu-banner',
        },
      });
      this.closeModal();
    },
    openMyProgress() {
      Segment.trackUserInteraction('NavigationMenu_MyProgress_BannerClicked');
      router.push({ name: 'MyProgress' });
      this.closeModal();
    },
    openChat() {
      Segment.trackUserInteraction('NavigationMenu_ChatWithTeamClicked');
      Intercom.show();
      this.closeModal();
    },
    goToPlanAheadPage() {
      Segment.trackUserInteraction('NavigationMenu_PlanAheadDashboardClicked');
      Segment.trackUserGot(
        'PlanAhead_IntroductionPopup_Test',
        { showIntro: this.showPlanAheadIntroPopup, hasPlans: this.hasPlans, source: 'NavigationMenu' },
      );
      if (!this.hasPlans) {
        if (this.showPlanAheadIntroPopup) {
          Segment.trackUserGot('PlanAhead_IntroductionPopup_Open', { source: 'NavigationMenu' });
          this.openModal({
            component: PlanAheadIntroductionPopup,
            popupAlignment: 'full-screen',
          });
        } else {
          this.openModal({
            component: AddPlanFlow,
            props: {
              onDone: this.addPlan,
            },
            popupAlignment: 'full-screen',
          });
        }
      }
      this.closeModal();
    },
    openPromotion() {
      Segment.trackUserInteraction('NavigationMenu_PromotionClicked', { promotionKey: this.promotion.key });
      this.promotion.onClick();
    },
    navigate(name) {
      router.push({ name, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/box-shadow';

.nav-bar {
  min-height: 100%;
  background: $riseup_white;
  box-shadow: $ri-large-box-shadow;
  border-radius: $card-border-radius 0 0 $card-border-radius;
  padding: $ri-spacing-l $ri-spacing-ml;
  outline: none;

  .nav-header {
    margin-bottom: $ri-spacing-l;
  }

  .promotion-banner { // use me for new banners!
    width: 100%;
    margin-bottom: $ri-spacing-ml;
    border-radius: $ri-border-radius-medium;
    cursor: pointer;
  }

  .tiles-grid {
    margin-top: $ri-spacing-l;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $ri-spacing-sm;
    grid-row-gap: $ri-spacing-sm;
  }
}
</style>
