import _ from 'lodash';
import sumsumApi from '@/api/SumsumApi';

const state = {
  bites: [],
  selectedBite: null,
};

const getters = {
};

const actions = {
  async initBites({ commit }) {
    const bites = await sumsumApi.getBites();
    commit('setBites', bites);
    commit('setSelectedBite', bites.length ? bites[0] : null);
  },

  async markBiteAsDismissed({ commit, state }, dismissedBiteId) {
    await sumsumApi.createBiteInput({ biteId: dismissedBiteId, type: 'dismissed' });
    commit('setBites', _.filter(state.bites, bite => bite._id !== dismissedBiteId));
  },

  async markBiteAsViewed({ commit, state }, viewedBiteId) {
    await sumsumApi.createBiteInput({ biteId: viewedBiteId, type: 'viewed' });
    const updatedBites = _.map(state.bites, bite => (bite._id === viewedBiteId ? { ...bite, viewed: true } : bite));
    commit('setBites', updatedBites);
  },
};

const mutations = {
  setBites(state, bites) {
    state.bites = bites;
  },
  setSelectedBite(state, bite) {
    state.selectedBite = bite;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
