<template>
  <div calss="zero-balance-wrapper">
    <insight class="zero-balance"
             :insight="insight"
             :animation-name="insightParams.animationName"
             :button-action="buttonAction"
             :button-text="insightButtonText">
      <template v-slot:insight-text>
        <div class="text">
          <span>זיהינו שה</span><!--
          --><span>{{ insightParams.expenseOrIncomeText }}</span>
          <span> הקבועה</span>
          <span>{{ insightParams.fromOrTo }}</span>
          <span class="business-name">"{{ shortenedBusinessName }}"</span>
          <span class="business-name">בסכום של</span>
          <span class="amount">{{ formattedAmount }} ₪</span>
          <span> לא</span>
          <span v-if="showNewFixedFeatures">
            <span>{{ insightParams.expenseOrIncome }}</span>
            <span>עדיין. מה לעשות עכשיו?</span>
          </span>
          <span v-else>
            <span>{{ insightParams.expenseOrIncome }}</span><span v-if="hasRemainingWeeklyVariables">, יתרת המשתנות
            {{ insightParams.variableBalanceText }} בהתאם</span>
          </span>
        </div>
      </template>
    </insight>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import UpdateEnvelopeBalanceDate
  from '../cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-predicted-transaction/UpdateEnvelopeBalanceDate';
import CancelPredictionSummary
  from '../cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-predicted-transaction/CancelPredictionSummary';
import Insight from './components/Insight';

export default {
  name: 'ZeroBalanceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['remainingWeeklyVariables', 'fixedIncomeUiCategories', 'fixedExpenseUiCategories']),
    ...mapGetters('featureFlags', ['showNewFixedFeatures']),
    predictedTransaction() {
      const categories = this.insight.isIncome ? this.fixedIncomeUiCategories : this.fixedExpenseUiCategories;
      const { transactions } = categories.find(c => c.label === this.insight.expense);
      return transactions.find(t => t.envelopeId === this.insight.envelopeId);
    },
    amountDifference() {
      return Math.abs(this.insight.originalAmount - this.insight.balancedAmount);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amountDifference, 0);
    },
    insightParams() {
      if (this.insight.isIncome) {
        return {
          variableBalanceText: 'קטנה',
          expenseOrIncomeText: 'הכנסה',
          fromOrTo: 'מ',
          expenseOrIncome: 'נכנסה',
          animationName: 'zero-balance-income.json',
        };
      }
      return {
        variableBalanceText: 'גדלה',
        expenseOrIncomeText: 'הוצאה',
        fromOrTo: 'על',
        expenseOrIncome: 'ירדה',
        animationName: 'zero-balance-expense.json',
      };
    },
    shortenedBusinessName() {
      if (this.insight.businessName.length > 15) {
        return `${this.insight.businessName.slice(0, 15)}...`;
      }
      return this.insight.businessName;
    },
    hasRemainingWeeklyVariables() {
      return this.remainingWeeklyVariables > 0;
    },
    insightButtonText() {
      return this.showNewFixedFeatures ? 'עדכון ופרטים נוספים' : 'הבנתי, תודה';
    },
    insightPrefix() {
      return this.insight.isIncome ? `הכנסות קבועות • ${this.insight.expense}` : `הוצאות קבועות • ${this.insight.expense}`;
    },
    popupTitle() {
      return `זיהינו שה${this.insightParams.expenseOrIncomeText} הקבועה על ${this.insight.businessName} בסכום של
      ${this.formattedAmount} ₪ לא ${this.insightParams.expenseOrIncome} עדיין. מה לעשות עכשיו?`;
    },
    detailsContent() {
      return this.insight.isIncome ? 'לא צפויה להגיע' : 'לא צפוי לרדת';
    },
    buttons() {
      const buttons = [
        {
          text: 'למחוק את ההוצאה',
          variant: 'primary',
          action: async () => {
            this.openCancelEnvelopePopUp();
          },
        },
        {
          text: 'לעדכן את התאריך',
          variant: 'primary',
          action: async () => {
            this.openModal({
              component: UpdateEnvelopeBalanceDate,
              props: {
                predictedTransaction: this.predictedTransaction,
                closeAction: this.closePopup,
                nextAction: this.openCancelEnvelopePopUp,
              },
            });
          },
        }];
      return buttons;
    },
    cashflowCategory() {
      return this.insight.isIncome ? cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME : cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals', 'closeModal']),
    async buttonAction() {
      if (this.showNewFixedFeatures) {
        this.openModal({
          component: TransactionInsightPopup,
          props: {
            insight: this.insight,
            insightPrefix: this.insightPrefix,
            insightPopupTitle: this.popupTitle,
            cashflowCategory: this.cashflowCategory,
            buttons: this.buttons,
            showFeedbackFooter: true,
            detailsContent: this.detailsContent,
          },
        });
      } else {
        await this.markAsRead(this.insight);
      }
    },
    async closePopup() {
      await this.markAsRead(this.insight);
      this.closeAllModals();
    },
    openCancelEnvelopePopUp() {
      this.openModal({
        component: CancelPredictionSummary,
        props: {
          predictedTransaction: this.predictedTransaction,
          closeAction: this.closePopup,
          backAction: this.closeModal,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>

.zero-balance {
  .text {
    .amount {
      font-weight: bold;
    }
    .business-name {
      font-weight: bold;
    }
  }
}
</style>
