<template>
  <div class="progress-bar">
    <div class="progress-bar-labels">
      <div class="right-side">
        <div class="label" :class="{ 'grayed-out': grayedOut }">{{ rightLabel }}</div>
        <div class="number bold" :class="[cashflowCategory, {'grayed-out': grayedOut}]">
          <span v-if="placeholder">{{ placeholder }}</span>
          <decimal-number v-else :value="rightAmount" /> ₪
        </div>
      </div>

      <div v-if=!hideLeftLabel class="left-side" :class="{ 'grayed-out': grayedOut }">
        <div class="label">{{ leftLabel }}</div>
        <div class="number" :class="{ 'grayed-out': grayedOut }">
          <span v-if="placeholder">{{ placeholder }}</span>
          <animated-decimal-number v-else :value="totalAmount" /> ₪
        </div>
      </div>
    </div>

    <animated-bar :grayed-out="grayedOut"
                  :totalAmount="totalAmount"
                  :up-to-now-amount="rightAmount"
                  :cashflow-category="cashflowCategory"
                  :animate-in-viewport="animateInViewport"
                  :filtered-amount="filteredAmount" />
    <div class="bottom-line">
      <div v-if="showFilter" class="filtered-amount">
        <icon icon-name="filter_alt" size="14px"/>{{ formatAmount(filteredAmount) }}
      </div>
      <div v-else />
      <div>
        <div v-if="isExceedingAmount" class="amount-diff" :class="{ 'grayed-out': grayedOut, danger }">
          <div class="icon">
            <img :src="require(`@/assets/icons/${exclamationMarkPath}`)">
          </div>
          חריגה של {{ amountsDiff }}
        </div>
        <div v-if="!hideLeftLabel && !isExceedingAmount && isTrackingCategory" class="amount-diff" :class="{ 'grayed-out': grayedOut }">
          נשאר להוציא {{ amountsDiff }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '../../../../../../../../../utils/money';
import AnimatedBar from '../../../../../../../../../base-components/AnimatedBar.vue';
import AnimatedDecimalNumber from '../../../../../../../../../base-components/AnimatedDecimalNumber.vue';
import DecimalNumber from '../../../../../../../../../base-components/DecimalNumber.vue';

export default {
  name: 'ProgressBar',
  components: {
    AnimatedDecimalNumber,
    AnimatedBar,
    DecimalNumber,
    Icon: BaseUI.Icon,
  },
  props: {
    rightLabel: {
      type: String,
      required: true,
    },
    rightAmount: {
      type: Number,
      required: false,
    },
    leftLabel: {
      type: String,
      required: true,
    },
    leftAmount: {
      type: Number,
      required: false,
    },
    showWarningIfExceedingAmount: {
      type: Boolean,
      required: false,
    },
    hideLeftLabel: {
      type: Boolean,
      default: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    grayedOut: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    animateInViewport: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    filteredAmount: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isExceedingAmount() {
      return this.showWarningIfExceedingAmount && this.rightAmount > this.leftAmount;
    },
    amountsDiff() {
      const diff = Math.abs(Math.trunc(this.leftAmount - this.rightAmount));
      const formattedDiff = moneyUtils.formatAmountWithFraction(diff, 0);
      return `${formattedDiff} ₪`;
    },
    totalAmount() {
      return _.max([this.leftAmount, 0]);
    },
    exclamationMarkPath() {
      if (this.grayedOut) {
        return 'exclemation-mark-gray.svg';
      }
      if (this.isTrackingCategory) {
        return 'exclemation-mark-blue.svg';
      }
      return 'exclemation-mark-red.svg';
    },
    danger() {
      return this.cashflowCategory === 'variable';
    },
    isTrackingCategory() {
      return this.cashflowCategory === 'tracking';
    },
  },
  methods: {
    formatAmount(amount) {
      const formattedAmount = moneyUtils.formatAmountWithFraction(amount);
      return `${formattedAmount} ₪`;
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../scss/category-color-mixins';

  .progress-bar {
    margin-top: 15px;

    .progress-bar-labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      color: $riseup_black;
      font-size: 10px;

      .left-side {
        text-align: left;

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }

      .label {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 11px;

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }

      .number {
        font-size: 18px;
        line-height: 21px;
        @include category-color;

        &.bold {
          font-weight: bold;
        }

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }
    }

    .danger-bar {
      height: 15px;
    }

    .bottom-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
      font-weight: bold;
      line-height: 10px;

      .filtered-amount {
        display: flex;
        align-items: center;
        color: $riseup_dark_blue;

        .icon {
          margin-bottom: 2px;
          margin-left: 4px;
        }
      }

      .amount-diff {
        .icon {
          display: inline-block;
          margin-left: 2px;
          vertical-align: middle;
          margin-bottom: 5px;
          height: 13px;
        }

        &.danger {
          color: $riseup_danger_red;
        }

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }
    }
  }
</style>
