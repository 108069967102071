import _ from 'lodash';
import Vue from 'vue';

import * as moment from 'moment';
import { getSourceConfigurationByID, getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import AggregatorApi from '@/api/AggregatorApi';
import Segment from '../../Segment';
import CredentialsApi from '../../api/CredentialsApi';
import DDLogs from '../../DDLogs';

const state = {
  credentialsList: [],
  credsToAccounts: [],
  missingBankCCAccountsAfterOBKMigration: [],
  missingRegularCCAccountsAfterOBKMigration: [],
  consents: [],
  sourceType: null,
  credentialsValid: true,
  credentialsSaveError: false,
  credentialsErrorMessage: '',
  isLoading: false,
  loadingStartTime: null,
  asyncTimeoutReached: false,
  timeout: null,
  nameError: null,
  updatedCredentialsSourceName: '',
  closedBankingCredentialsIdToUpdate: undefined,
  connectedConsentMetadata: undefined,
  obkSourceConfigurations: null,
  loadingCredsSourceName: null,
};

const TIMEOUT_IN_SECS = 60;
const LOADING_TIMEOUT_IN_MINUTES = 60;

const getters = {
  credsBySourceType: state => (state.sourceType ? _.filter(state.credentialsList, { type: state.sourceType }) : state.credentialsList),
  invalidCredentialsList: (state, getters) => _.filter(getters.credsBySourceType, { status: 'passwordInvalid' }),
  validCredentialsList: (state, getters) => _.filter(getters.credsBySourceType, creds => ['valid', 'obkSourceFailure'].includes(creds.status)),
  unvalidatedCredentialsList: (state, getters) => _.filter(getters.credsBySourceType, { status: 'unvalidated' }),
  partiallyAuthorizedCredentialsList: (state, getters) => _.filter(getters.credsBySourceType, { status: 'partiallyAuthorized' }),
  invalidCredentials: (state, getters) => getters.invalidCredentialsList.length,
  connectedCredentials: (state, getters) => [...getters.validCredentialsList, ...getters.unvalidatedCredentialsList,
    ...getters.partiallyAuthorizedCredentialsList],
  connectedBankCredentials: (state, getters) => getters.connectedCredentials.filter(c => c.type === 'bank'),
  accountsList: _getAccountsList,
  accountListDict: (state, getters) => _.keyBy(getters.accountsList, 'accountNumberPiiId'),
  credentialsLoadingTooLong: state => state.isLoading && moment().diff(moment(state.loadingStartTime), 'minutes') > LOADING_TIMEOUT_IN_MINUTES,
  getCredsByCredentialsId: state => credentialsId => state.credentialsList.find(c => c.credentialsId === credentialsId),
  closedBankingCredentialsToUpdate: (state, getters) => getters.getCredsByCredentialsId(state.closedBankingCredentialsIdToUpdate),
  validNonOBkCredentials: (state, getters) => _.reject(getters.validCredentialsList, c => !!c.openBankingConsentId),
  getCredsBankCCAccounts: (state, getters) => credentialsId => {
    const creds = getters.getCredsByCredentialsId(credentialsId);
    if (!creds || creds.type !== 'bank') {
      return [];
    }
    return _.filter(creds.accounts, account => account.sourceType === 'card');
  },
  isAllOBKSourcesValid: (state, getters) => _.chain(state.credentialsList)
    .filter(creds => !!creds.openBankingConsentId)
    .map('id')
    .uniq()
    .every(sourceName => !getters.getDisabledOBKSources.includes(sourceName))
    .value(),
  getOwnDisabledOBKSources: (state, getters) => _.chain(state.credentialsList)
    .filter(creds => !!creds.openBankingConsentId)
    .map('id')
    .uniq()
    .filter(id => getters.getDisabledOBKSources.includes(id))
    .value(),
  getDisabledOBKSources: (state, getters) => _.chain(state.obkSourceConfigurations)
    .filter({
      enabled: false,
      type: 'enableConsentCreation',
    })
    .map(config => config.bankIdentifier)
    .value(),
  allConnectedCredentialsAreValid: (state, getters) => _.every(
    getters.connectedCredentials,
    cred => ['valid', 'partiallyAuthorised'].includes(cred.status),
  ),
  enableCredentialsActions: (state, getters) => getters.allConnectedCredentialsAreValid
    && getters.isAllOBKSourcesValid && _.isEmpty(getters.invalidCredentialsList),
  disabledSourceName: (state, getters) => getSourceConfigurationByID(getters.getOwnDisabledOBKSources[0]).displayName,
  latestScrapedAt: state => _.chain(state.credentialsList)
    .map(a => a.lastScrapedAt)
    .max()
    .value(),
};

const actions = {
  async fetchLoadingCredentialsState({ commit }) {
    const { state } = await CredentialsApi.fetchLoadingCredentialsState();
    if (state) {
      if (state.success !== undefined) {
        commit('setCredentialsValid', state.success);
      }
      if (state.loadingStartTime) {
        commit('setLoadingStartTime', moment(state.loadingStartTime).toDate());
      } else {
        commit('setLoadingStartTime', null);
      }
      commit('setIsLoading', state.loading);
      commit('setLoadingCredsSourceName', state.sourceName);
    }
  },
  async deleteCredentials({ commit, dispatch }, { credentialsId }) {
    await CredentialsApi.deleteCredentials({ credentialsId });
    await dispatch('fetchCredentials');
  },
  async fetchCredsToAccounts({ commit }) {
    const credsToAccounts = await CredentialsApi.fetchCredsToAccounts();
    commit('setCredsToAccounts', credsToAccounts);
  },
  async fetchMissingCCAccountsAfterOBKMigration({ commit }) {
    const { bankCCs, regularCCs } = await CredentialsApi.fetchMissingCCsAfterOBKMigration();
    commit('setMissingBankCCAccountsAfterOBKMigration', bankCCs);
    commit('setMissingRegularCCAccountsAfterOBKMigration', regularCCs);
  },
  async fetchConsents({ commit }) {
    try {
      const consents = await AggregatorApi.getConsents();
      commit('setConsents', consents);
    } catch (e) {
      // this is to fix the e2e test
    }
  },
  async fetchCredentials({ commit, state, dispatch }) {
    const rawCredentialsList = await CredentialsApi.fetchCredentials();
    await dispatch('fetchCredsToAccounts');
    await dispatch('fetchMissingCCAccountsAfterOBKMigration');
    await dispatch('fetchConsents');
    const convertedCredentialsRaws = _.map(rawCredentialsList, _.partial(_convertRawCredentials, state.credsToAccounts));
    const credentialsList = _.map(convertedCredentialsRaws, cred => {
      const consent = _.find(state.consents, c => c.consentData.consentId === cred.openBankingConsentId);
      return {
        ...cred,
        ...(consent ? { idNumber: consent.idNumber } : { idNumber: '' }),
      };
    });
    commit('setCredentialsList', credentialsList);
  },
  async fetchObkSourceConfigurations({ commit, state, dispatch }) {
    const obkSourceConfigurations = await AggregatorApi.getSourceConfigurations();
    commit('setObkSourceConfigurations', obkSourceConfigurations);
  },
  async updateCredentials({ commit, state, dispatch }, { source, credentials, isSync, renew = false }) {
    commit('setIsLoading', true);
    const credentialsObject = _.find(state.credentialsList, { credentialsId: source.credentialsId });
    const { name, sourceName, credentialsId, provider } = credentialsObject;
    commit('resetCredentialsValidation');

    const checkCredsResult = await _checkCredentials(sourceName, credentials, credentialsId, renew, provider);
    if (checkCredsResult.reason === 'ValidCredentials') {
      if (!isSync) {
        commit('setIsLoading', false);
      }
      commit('setCredsStatus', { credentialsId: source.credentialsId, status: 'valid' });
      dispatch('createTimeout', 0);
      return await _tryUpdatingCreds(commit, sourceName, { credentialsId, credentials, name, isSync });
    }
    if (checkCredsResult.reason === 'RequestFailed') {
      commit('setCredentialsSaveError');
    } else {
      commit('setCredsValid', { credsValid: false });
      commit('setCredentialErrorMessage', { message: checkCredsResult?.reason });
    }
    Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: checkCredsResult.reason });
    commit('setIsLoading', false);
    return { success: false };
  },
  async updateCredentialsName({ commit, dispatch, state }, { source }) {
    const credentialsObject = _.find(state.credentialsList, { credentialsId: source.credentialsId });
    const { fields: credentialsFields, name, status, sourceName, credentialsId } = credentialsObject;
    const credentials = _extractCredentials(credentialsFields);
    dispatch('createTimeout', 0);
    return await _tryUpdatingCreds(commit, sourceName, { credentialsId, credentials, name, status });
  },
  async saveCredentials({ commit, state, dispatch }, { sourceName, credentials, name, provider }) {
    commit('setIsLoading', true);
    commit('setLoadingStartTime', new Date());
    commit('resetCredentialsValidation');
    const checkCredsResult = await _checkCredentials(sourceName, credentials, null, false, provider);
    if (checkCredsResult.reason === 'ValidCredentials') {
      dispatch('createTimeout', 0);
      return await _trySavingCreds(commit, sourceName, credentials, name, provider);
    }
    if (checkCredsResult.reason === 'InvalidCredentials') {
      commit('setCredsValid', { credsValid: false });
    } else {
      commit('setCredentialsSaveError');
    }
    commit('setIsLoading', false);
    Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: checkCredsResult.reason });
    return { success: false };
  },
  async setName({ commit }, { credentialsId, name }) {
    commit('setCredentialsName', { credentialsId, name });
  },
  async handleLoadingState({ commit, state, dispatch }) {
    if (!state.isLoading) {
      return;
    }
    const loadingTimeInSecs = moment().diff(moment(state.loadingStartTime), 'seconds');
    if (loadingTimeInSecs > TIMEOUT_IN_SECS) {
      commit('setAsyncTimeoutReached', true);
    } else {
      dispatch('createTimeout', loadingTimeInSecs);
    }
  },
  async createTimeout({ commit, state, dispatch }, loadingTimeInSecs) {
    const timeout = setTimeout(async () => {
      await dispatch('fetchLoadingCredentialsState');
      if (state.isLoading) {
        commit('setAsyncTimeoutReached', true);
      }
    }, (TIMEOUT_IN_SECS - loadingTimeInSecs) * 1000);
    commit('setTimeout', timeout);
  },
  clearTimeout({ state }) {
    if (state.timeout) {
      clearTimeout(state.timeout);
    }
  },
  async renameAccount({ commit, state }, { source, accountNumberPiiId, accountNickname }) {
    try {
      if (accountNickname.length === 0) {
        await CredentialsApi.deleteAccountNickname(accountNumberPiiId);
      } else {
        await CredentialsApi.setAccountNickname(accountNumberPiiId, accountNickname);
      }

      const updatedCredentialsList = state.credentialsList.map(credential => {
        const updatedAccounts = credential.accounts.map(account => {
          if (account.accountNumberPiiId === accountNumberPiiId) {
            return { ...account, accountNickname };
          }
          return account;
        });

        if (updatedAccounts.some(account => account.accountNumberPiiId === accountNumberPiiId)) {
          return { ...credential, accounts: updatedAccounts };
        }
        return credential;
      });

      commit('setCredentialsList', updatedCredentialsList);

      Segment.trackUserGot('AccountNameSaveSuccess', {
        sourceName: source.name,
        accountNumberPiiId,
      });
      return { success: true };
    } catch (e) {
      Segment.trackUserGot('AccountNameSaveFailed', {
        sourceName: source.name,
        accountNumberPiiId,
      });
      return { success: false };
    }
  },
};

const mutations = {
  setSourceType(state, sourceType) {
    state.sourceType = sourceType;
  },
  setTimeout(state, timeout) {
    state.timeout = timeout;
  },
  setCredentialsValid(state, credentialsValid) {
    state.credentialsValid = credentialsValid;
  },
  setCredentialsList(state, credentialsList) {
    state.credentialsList = credentialsList;
  },
  setCredsToAccounts(state, credsToAccounts) {
    state.credsToAccounts = credsToAccounts;
  },
  setConsents(state, consents) {
    state.consents = consents;
  },
  setMissingBankCCAccountsAfterOBKMigration(state, missingBankCCAccountsAfterOBKMigration) {
    state.missingBankCCAccountsAfterOBKMigration = missingBankCCAccountsAfterOBKMigration;
  },
  setMissingRegularCCAccountsAfterOBKMigration(state, missingRegularCCAccountsAfterOBKMigration) {
    state.missingRegularCCAccountsAfterOBKMigration = missingRegularCCAccountsAfterOBKMigration;
  },
  setLoadingStartTime(state, loadingStartTime) {
    state.loadingStartTime = loadingStartTime;
  },
  setAsyncTimeoutReached(state, asyncTimeoutReached) {
    state.asyncTimeoutReached = asyncTimeoutReached;
  },
  setCredentialsSaveError(state) {
    state.credentialsSaveError = true;
  },
  setCredentialErrorMessage(state, { message }) {
    state.credentialsErrorMessage = message;
  },
  resetCredentialsValidation(state) {
    state.credentialsErrorMessage = '';
    state.credentialsSaveError = false;
    state.credentialsValid = true;
  },
  setCredsValid(state, { credsValid }) {
    state.credentialsValid = credsValid;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setUpdatedCredentialsSourceName(state, sourceName) {
    state.updatedCredentialsSourceName = sourceName;
  },
  setNameError(state, { error }) {
    state.nameError = error;
  },
  setCredentialsName(state, { credentialsId, name }) {
    const account = _.find(state.credentialsList, { credentialsId });
    Vue.set(account, 'name', name);
  },
  setCredsStatus(state, { credentialsId, status }) {
    const credentials = _.find(state.credentialsList, { credentialsId });
    Vue.set(credentials, 'status', status);
  },
  setClosedBankingCredentialsIdToUpdate(state, closedBankingCredentialsIdToUpdate) {
    state.closedBankingCredentialsIdToUpdate = closedBankingCredentialsIdToUpdate;
  },
  setConnectedConsentMetadata(state, connectedConsentMetadata) {
    state.connectedConsentMetadata = connectedConsentMetadata;
  },
  setObkSourceConfigurations(state, obkSourceConfigurations) {
    state.obkSourceConfigurations = obkSourceConfigurations;
  },
  setLoadingCredsSourceName(state, loadingCredsSourceName) {
    state.loadingCredsSourceName = loadingCredsSourceName;
  },
};

// These are all helper methods
function _getAccountsList(state) {
  return _.chain(state.credentialsList).map(creds => _.map(creds.accounts, account => _getAccountWithExtraParams(creds, account))).flatten().value();
}

function _getAccountWithExtraParams(creds, account) {
  return {
    ...account,
    sourceName: creds.sourceName,
    name: creds.name,
    accounts: [account],
    credentialsId: creds.credentialsId,
  };
}

async function _checkCredentials(sourceName, credentials, credentialsId, renew, provider) {
  const result = await _checkCredentialsInternal(sourceName, credentials, credentialsId, renew, provider);
  Segment.trackUserGot('CheckCredentials', { isValid: result.isValid, reason: result.reason, sourceName, renew, provider });
  if (!_.isNil(credentialsId)) {
    CredentialsApi.logCredsCheck(credentialsId, result.isValid, result.reason)
      .catch(err => DDLogs.error('Failed logging creds check result', { err, credentialsId, result }));
  }
  return result;
}

async function _checkCredentialsInternal(sourceName, credentials, credentialsId, renew, provider) {
  try {
    const result = await CredentialsApi.checkCredentials({ sourceName, credentials, credentialsId, renew, provider });
    if (result.reason === 'ValidCredentials') {
      return { isValid: true, reason: result.reason };
    }
    return { isValid: false, reason: result.reason };
  } catch (error) {
    DDLogs.error('Creds check failed with error', { error, credentialsId, sourceName, renew, provider });
    return { isValid: false, reason: 'RequestFailed' };
  }
}

function _extractCredentials(credentialsFields) {
  return _.chain(credentialsFields)
    .mapValues(val => val.value)
    .omitBy(val => !val)
    .mapValues(val => val.trim())
    .value();
}

async function _tryUpdatingCreds(commit, sourceName, payload) {
  try {
    commit('setLoadingStartTime', new Date());
    await CredentialsApi.updateCredentials(payload);
    Segment.trackUserGot('CredentialsSaveSuccess', { sourceName });
    return { success: true };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      commit('setNameError', { credentialsId: payload.credentialsId, error: 'כבר השתמשת בשם הזה' });
      Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: 'SourceNameAlreadyExists' });
      return { success: false };
    }
    Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: 'UnexpectedException' });
    commit('setUpdateError', { credentialsId: payload.credentialsId });
    commit('setCredentialsSaveError');
    return { success: false };
  }
}

async function _trySavingCreds(commit, sourceName, credentials, name, provider) {
  try {
    await CredentialsApi.saveCredentials({ sourceName, credentials, name, provider });
    Segment.trackUserGot('CredentialsSaveSuccess', { sourceName });
    return { success: true };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      commit('setNameError', { sourceName, error: 'כבר השתמשת בשם הזה' });
      Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: 'SourceNameAlreadyExists' });
      return { success: false };
    }
    Segment.trackUserGot('CredentialsSaveFailed', { sourceName, reason: 'UnexpectedException' });
    commit('setSaveError', { sourceName });
    commit('setCredentialsSaveError');
    return { success: false };
  }
}

function _convertRawCredentials(credsToAccounts, rawCredentials) {
  const credToAccounts = _.find(credsToAccounts, credToAccounts => credToAccounts.credentialsId === rawCredentials.credentialsId);
  const sourceFieldsObject = _getSourceFieldsObject(rawCredentials.sourceName);
  return _.assign({}, rawCredentials, sourceFieldsObject, { accounts: credToAccounts && credToAccounts.accountNumberPiiIds });
}

function _getSourceFieldsObject(sourceName) {
  const source = getSourceConfigurationBySourceName(sourceName);
  const fieldsWithValue = _.mapValues(source.fields, fieldValue => _.assign({ value: undefined }, fieldValue));
  return _.assign(
    {},
    source,
    { fields: fieldsWithValue },
  );
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
